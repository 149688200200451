import * as React from "react"
import Slider, {LazyLoadTypes} from 'react-slick'
import {ArtistItemInterface} from "../reducer/type/home";
import { Link } from 'react-router-dom'

interface PropsInterface {
    artist: object[]
}

class HomeLienkhuc extends React.Component <PropsInterface> {

    // constructor(props: PropsInterface) {
    //     super(props)
    // }

    render() {
        console.log(this.props.artist);

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            lazyLoad: "ondemand" as LazyLoadTypes
        };

        let homeArtist = this.props.artist.map((item: any, key: number) => {
            return (
                <div className="pl-2 pr-2" key={key}>
                    <Link to={'/play/'+ item.id + '-' + item.slug} className="content-item">
                        <img className="content-item" src={ item.thumbnail_url }/>
                        <div className="content-item-head">
                            {item.name}
                        </div>
                    </Link>
                </div>
            );
        });

        return (
            <div className='col-12'>
                <Slider {...settings} className="home-content-slide">
                    {homeArtist}
                </Slider>
            </div>
        );
    }

}

export default HomeLienkhuc

// @ts-ignore
import * as React from "react";
import videojs from 'video.js'
// @ts-ignore
import qualitySelector from 'videojs-hls-quality-selector';
// @ts-ignore
import qualityLevel from 'videojs-contrib-quality-levels';

interface PropsInterface {
    videoProperties: {}
    onEnded: () => void;
}


class Player extends React.Component<PropsInterface> {

    private player: videojs.Player;
    private videoNode: any;

    constructor(props: PropsInterface) {
        super(props);
    }

    componentDidMount() {
        videojs.registerPlugin('qualityLevel', qualityLevel);
        videojs.registerPlugin('hlsQualitySelector', qualitySelector);

        // @ts-ignore
        this.player = videojs(this.videoNode, this.props.videoProperties);
        // @ts-ignore
        let qualityLevels = this.player.qualityLevels();
        // @ts-ignore
        this.player.hlsQualitySelector({
            displayCurrentQuality: true,
        });


    }

    componentDidUpdate(prevProps: Readonly<{}>) {
        // @ts-ignore
        if (this.props.videoProperties.update_detect) {
            // @ts-ignore
            this.player.src(this.props.videoProperties.sources[0]);
        }
        //@ts-ignore LAMNV=====================
        if (this.props.videoProperties.loop !== prevProps.videoProperties.loop){
            //@ts-ignore
            if (this.props.videoProperties.loop === true){
                this.videoNode.loop = true;
            }else{
                this.videoNode.loop = false;
            }
        }
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    onEndedVideo(event: React.SyntheticEvent<HTMLVideoElement, Event>) {
        // this.player.src([{
        //         src: "http://redirector.ibolero.vn/hls/2a663e36d5cb206e9e9cb937e33dfab3/master.m3u8?st=-qX2NrH56AAH2pyw4sve6w&e=1650013353",
        //         type: 'application/x-mpegURL'
        //     }
        // ]);
        // this.player.play().then(response => {
        //     console.log(response);
        // }).then(error => {
        //     console.log(error);
        // })
        // //@ts-ignore
        // const list: {} = this.props.listSong;
        // console.log(">>>>>>onEndedVideo:" + list[0].link_stream);
        // const videoJsOptions = {
        //     autoPlay: true,
        //     controls: true,
        //     sources: [{
        //         src: list[1].link_stream,
        //         type: 'application/x-mpegURL'
        //     }],
        //     controlBar: {
        //         pictureInPictureToggle: false
        //     },
        // }
        // //@ts-ignore
        // this.player = videojs(this.videoNode, videoJsOptions);
        // this.player.play().then(response=> {
        //     console.log(response);
        // }).catch(e=>{
        //
        // })
        // this.player.selectSource([
        //     {
        //         src: list[0].link_stream,
        //         type: 'application/x-mpegURL'
        //     }
        // ])
        // this.player.play().then(response => {
        //     console.log(response)
        //
        // }).catch(e => {
        //     console.log(e);
        // });
    }
    render() {
        return (
            <div>
                <div data-vjs-player={true}>
                    <video ref={ node => this.videoNode = node } className="video-js vjs-16-9" id="iboleroVideo" onEnded={(e) => this.props.onEnded()}/>
                </div>
            </div>
        )
    }

}

export default Player
// @ts-ignore

import * as React from "react"
import * as $ from 'jquery'

class LoadingScreen extends React.Component {

    render () {
        let mTop = ($(window).height() - 100) / 2;
        let mLeft = ($(window).width() - 100) / 2;

        return (
            <div className="loading-screen">
                <div className="loading-screen-img" style={{
                    marginTop: mTop,
                    marginLeft: mLeft
                }}>
                    <img src={ require("../asset/images/spinner2.gif") } />
                </div>
            </div>
        );
    }

}

export default LoadingScreen
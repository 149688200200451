import * as React from "react"
import * as $ from 'jquery'

import { Link } from 'react-router-dom'
import StateInterface from "../reducer/type"
import {connect} from "react-redux"
import { CategoryState, CategoryItemInterface } from "../reducer/type/category"
import {AuthState} from "../reducer/type/auth";
import * as ls from "local-storage";

interface StatePropsInterface {
    category: CategoryState,
    auth: AuthState
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    auth: state.auth
});

type PropsInterface = StatePropsInterface;

class Menu extends React.Component <PropsInterface> {

    componentDidMount () {
        // Menu Action
        $('.menu-content').height($(window).height() - 74);
        $('.menu-overlay').height($(window).height() - 74);
        $('.menu-list').height($(window).height() - 74);
        $('.menu-left').on('click', function () {
            if ( $('.menu-overlay').css("display") == 'none' ) {
                $('.menu-content').height($(window).height() - 74);
                $('.menu-overlay').height($(window).height() - 74);
                $('.menu-content').css({left: 0});
                $('body').addClass('over-hidden');
                $('.menu-overlay').show();
            } else {
                $('.menu-content').css({left: "-70%"});
                $('body').removeClass('over-hidden');
                $('.menu-overlay').hide();
            }
        });
        $('.menu-overlay').on('click', function () {
            $('.menu-content').css({left: "-70%"});
            $('body').removeClass('over-hidden');
            $('.menu-overlay').hide();
        });

        // Menu
        $('.sub-menu').on('click', function () {
            if ( $('.sub-menu-content').css("display") == 'none' ) {
                $('.sub-menu-content').slideDown();
                $('.menu-sub-img').css({
                    transform: 'initial'
                })
            } else {
                $('.sub-menu-content').slideUp()
                $('.menu-sub-img').css({
                    transform: 'rotate(180deg)'
                })
            }
        });

        $('.menu-test').on('click', () => {
            if ( $('.sub-menu-content-2').css("display") == 'none' ) {
                console.log('544');
                $('.sub-menu-content-2').slideDown();
                $('.menu-sub-img-2').css({
                    transform: 'initial'
                })
            } else {
                $('.sub-menu-content-2').slideUp()
                $('.menu-sub-img-2').css({
                    transform: 'rotate(180deg)'
                })
            }
        });

        $('.sub-menu-2').on('click', function () {
            console.log(123);
            if ( $('.sub-menu-content-2').css("display") == 'none' ) {
                console.log('234');
                $('.sub-menu-content-2').slideDown();
                $('.menu-sub-img-2').css({
                    transform: 'initial'
                })
            } else {
                $('.sub-menu-content-2').slideUp()
                $('.menu-sub-img-2').css({
                    transform: 'rotate(180deg)'
                })
            }
        });

        $('.sub-menu-content').on('click', function () {
            console.log('sub-menu-content');
            $('.menu-content').css({left: "-70%"});
            $('body').removeClass('over-hidden');
            $('.menu-overlay').hide();
        });
    }

    componentWillUnmount() {
        $('.menu-content').css({left: "-70%"});
        $('body').removeClass('over-hidden');
        $('.menu-overlay').hide();
    }

    handleCategory(data) {
        window.location = data
    }

    render() {
        let subMenuContent = this.props.category.data.map((item: CategoryItemInterface) => {
            let enImage = (
                <div className="menu-chimen">
                    <img src={ require("../asset/image_tet/chimen.png") }/>
                </div>
            );

            return (
                <a onClick={ this.handleCategory.bind(this, '/danh-muc/' + item.id + '-' + item.slug) } key={ item.id } className="position-relative list-group-item menu-list-item">
                    { item.name }
                    { (item.name == "Nhạc Xuân tuyển chọn") ? enImage : null }
                </a>
            );
        })

        let loginLink;
        let infoUserLink;
        let logoutLink;
        let favoriteLink;
        let mainLink;
        if (typeof ls.get<string>('token') != 'string') {
            loginLink = (
                <li>
                    <Link to="/dang-nhap" className="menu-list-login menu-list-item">
                        <img className="menu-list-login-img" src={ require("../asset/images/icon-user.png") }/>
                        Đăng nhập
                    </Link>
                </li>
            );
        } else {

            logoutLink = (
                <Link to="/dang-xuat" className="list-group-item menu-list-item">
                    <img src={ require("../asset/images/icon-logout.png") }/>
                    Đăng xuất
                </Link>
            );

            if (typeof this.props.auth.data.phone == "string") {
                infoUserLink = (
                    <li className="sub-menu-2-test">
                        <a className="sub-menu-2 menu-list-login menu-list-item">
                            <img className="menu-list-login-img" src={require("../asset/images/icon-user.png")}/>
                            Xin chào {this.props.auth.data.phone.substring(0, 8)}****
                            <img className='menu-sub-img-2' src={ require("../asset/images/icon-menu-down.png") }
                                 style={{ right: '12px', left: 'auto', top: '20px', transform: 'rotate(180deg)', width: '10px' }}/>
                        </a>
                        <div className="list-group sub-menu-content-2">
                            <Link to="/doi-mat-khau" className="list-group-item menu-list-item">
                                Đổi mật khẩu
                            </Link>
                            <Link to="/dang-xuat" className="list-group-item menu-list-item">
                                Đăng xuất
                            </Link>
                        </div>
                        <div className="clearfix"></div>
                    </li>
                );
            }

            favoriteLink = (
                <li>
                    <a href="/video-yeu-thich" className="menu-list-item">
                        <img className="menu-list-login-img" src={ require("../asset/images/icon-heart-3.png") } style={{ top: '15px' }} />
                        Video yêu thích
                    </a>
                </li>
            );

            mainLink = (
                <div className="menu-test" style={{
                    position: "absolute",
                    width: '100%',
                    height: 45,
                    zIndex: 100000,
                    top: 0,
                    left: 0
                }} />
            );
        }

        return (
            <div className="menu-content">
                { mainLink }
                <ul className="menu-list">
                    {loginLink}
                    {infoUserLink}
                    {favoriteLink}
                    {/*<li>
                        <a href="https://service.ibolero.vn/voice/show" className="menu-list-item">
                            <img src={ require("../asset/images/icon-home.png") }/>
                            Tìm kiếm bằng giọng nói
                        </a>
                    </li>*/}
                    <li>
                        <a href="/" className="menu-list-item">
                            <img src={ require("../asset/images/icon-home.png") }/>
                            Trang chủ
                        </a>
                    </li>
                    <li>
                        <a href="/bang-xep-hang" className="menu-list-item">
                            <img src={ require("../asset/images/icon-top.png") } style={{
                                top: '14px',
                                width: '23px'
                            }}/>
                            Bảng xếp hạng
                        </a>
                    </li>
                    <li>
                        <a className="sub-menu">
                            <img src={ require("../asset/images/icon-music.png") } style={{ top: '15px' }}/>
                            {/*Chuyên mục*/}
                            Bạn muốn nghe gì?
                            <img className='menu-sub-img' src={ require("../asset/images/icon-menu-down.png") }
                                 style={{ right: '12px', left: 'auto', top: '20px', transform: 'rotate(180deg)', width: '10px' }}/>
                        </a>
                        <div className="list-group sub-menu-content">
                            { subMenuContent }
                        </div>
                    </li>
                    <li>
                        <a href="/lien-khuc" className="menu-list-item">
                            <img src={ require("../asset/images/icon-lienkhuc.png") } style={{
                                top: '12px',
                                width: '19px',
                                left: '23px'
                            }}/>
                            Liên khúc
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={ require("../asset/images/icon-karaoke.png") } />*/}
                    {/*        Karaoke*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li>
                        <a href="/ca-si">
                            <img src={ require("../asset/images/icon-mic.png") }/>
                            Ca sĩ
                        </a>
                    </li>
                    <li>
                        <a href="/goi-cuoc">
                            <img src={ require("../asset/images/icon-package.png") } style={{ top: 15 }}/>
                            Đăng ký xem video miễn cước 3G/4G
                        </a>
                    </li>
                    <li>
                        <a href="/gioi-thieu">
                            <img src={ require("../asset/images/icon-ibolero.png") } style={{ top: 5, width: 33, left: 14 }}/>
                            Giới thiệu iBolero
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a className="sub-menu-2">*/}
                    {/*        Thông tin Ibolero*/}
                    {/*        <img className='menu-sub-img-2' src={ require("../asset/images/icon-menu-down.png") }*/}
                    {/*             style={{ right: '12px', left: 'auto', top: '20px', transform: 'rotate(180deg)', width: '10px' }}/>*/}
                    {/*    </a>*/}
                    {/*    <div className="list-group sub-menu-content-2">*/}
                    {/*        <Link to="/gioi-thieu" className="list-group-item menu-list-item">*/}
                    {/*            Giới thiệu*/}
                    {/*        </Link>*/}
                    {/*        <Link to="/huong-dan-su-dung" className="list-group-item menu-list-item">*/}
                    {/*            Hướng dẫn sử dụng*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*{logoutLink}*/}
                    <div className={'clearfix'}></div>
                </ul>
            </div>
        );
    }

}

export default connect<StatePropsInterface>(
    mapStateToProps
)(Menu);

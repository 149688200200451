import * as React from "react"
import {Link} from "react-router-dom";
import { ApiLogDownload } from '../api/song'

interface PropsInterface {
    link: object[],
    song_id?: string | null
}

class ModalDownload extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    handleClickDownload (url) {
        if (this.props.song_id) {
            ApiLogDownload(this.props.song_id);
            let win = window.open(url, '_blank');
            win.focus();
        }
    }

    render() {
        let linkView = this.props.link.map((item, key) => {
            return (
                <div onClick={ this.handleClickDownload.bind(this, item[Object.keys(item)[0]]) } className="btn modal-download-btn mr-3" key={key}>
                        { Object.keys(item)[0] }
                </div>
            )
        });

        return (
            <div className="modal" role="dialog" id="modal-download">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center modal-alert-header">
                            Tải video
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 text-center mb-3">
                                    {linkView}
                                </div>
                            </div>
                            <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ModalDownload
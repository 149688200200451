import * as React from "react"
import LazyLoad from 'react-lazyload';

interface PropsInterface {
    item: any
}

class VideoItem extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    handleClickVideo (data) {
        // const player = window.jwplayer('video-player');
        // player.stop();
        window.location = data
    }

    render() {
        let link;
        if (window.location.pathname.indexOf('bang-xep-hang') > -1) {
            link = '/bang-xep-hang/play/'+ this.props.item.id + '-' + this.props.item.slug;
        } else {
            link = '/play/'+ this.props.item.id + '-' + this.props.item.slug;
        }
        return (
            <div onClick={this.handleClickVideo.bind(this, link)} className="col-12">
                <div className="row">
                    <div className="col-6">
                        <LazyLoad once={true}>
                            <div className="position-relative">
                                <img className="video-relate-img" src={ this.props.item.thumbnail_url }/>
                                <div className="content-item-time-right" style={{ bottom: '24px' }}>
                                    <div className="content-item-time">
                                        { this.props.item.time }
                                    </div>
                                </div>
                            </div>
                        </LazyLoad>
                    </div>
                    <div className="col-6 pl-0">
                        <div className="video-relate-title">
                            { this.props.item.name }
                        </div>
                        <div className="video-relate-sub">
                            { this.props.item.artist }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoItem

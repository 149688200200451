import * as React from "react"

class LoadMore extends React.Component {

    render () {

        return (
            <div className="col-12">
                <div className="load-more text-center">
                    Đang tải
                    <img src={ require("../asset/images/spinner2.gif") }/>
                </div>
            </div>
        );
    }

}

export default LoadMore
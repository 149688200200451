import * as React from "react"
import * as $ from 'jquery'
import {Link, Redirect} from 'react-router-dom'
import {
    changeToSlug
} from "../helper";
import { ApiSearchData } from '../api/home'
import {CategoryListItemInterface} from "../reducer/type/category";
import {ArtistItemInterface} from "../reducer/type/home";

interface PropsInterface {
    logo?: string
}

interface StateInterface {
    query: string,
    submit: boolean,
    suggestVideo: object[],
    suggestSinger: object[],
    width: number
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'marquee': any
        }
    }
}

class Header extends React.Component <PropsInterface, StateInterface> {

    static defaultProps = {
        logo: null
    }

    constructor (props: PropsInterface) {
        super(props)
        this.state = {
            query: '',
            submit: false,
            suggestVideo: [] as object[],
            suggestSinger: [] as object[],
            width: 0
        }
    }

    componentDidMount () {
        // Search
        this.setState({
            width: $(window).width() - 80
        });
        $('.search-form').on('focus', function () {
            $('.search-input').css({
                width: ($(window).width() - 80) + "px"
            });
            $('.logo-center').hide();
            $('.logo-center-2').hide();
            $('.search-suggest').show();
        });
        $('.search-form').on('focusout', function () {
            setTimeout(() => {
                $('.logo-center').show();
                $('.logo-center-2').show();
                $('.search-suggest').hide();
            }, 300);
            $('.search-input').css({
                width: "120px"
            });
        });
        $('.logo-center').on('click', function () {
            $('.menu-content').css({left: "-70%"});
            $('body').removeClass('over-hidden');
            $('.menu-overlay').hide();
        });
    }

    handleSearch (input) {
        if (input.target.value.length > 0) {
            ApiSearchData(input.target.value.toLowerCase()).then((response: any) => {
                this.setState({
                    suggestSinger: response.data.data.singer,
                    suggestVideo: response.data.data.media
                });
            });
        } else {
            this.setState({
                suggestSinger: [],
                suggestVideo: []
            });
        }
        this.setState({
            query: input.target.value
        });
    }

    handleSubmit (event) {
        event.preventDefault();
        this.setState({
            submit: true
        });
        window.location = '/tim-kiem/' + this.state.query.toLowerCase() as any;
    }

    render() {

        // if (this.state.submit) {
        //     return (<Redirect to={'/tim-kiem/' + changeToSlug(this.state.query)}/>)
        // }

        let logoData;
        if (this.props.logo) {
            logoData = (
                <div className="logo-center-2">
                    <Link to="/" title="Trang chu">
                        {this.props.logo}
                    </Link>
                </div>
            );
        } else {
            logoData = (
                <div className="logo-center">
                    <Link to="/" title="Trang chu">
                        <img className="logo-img" src={ require("../asset/images/logo.svg") } />
                    </Link>
                </div>
            );
        }

        let suggestSearch;
        let suggestVideo;
        let suggestSinger;
        if ( (this.state.suggestVideo.length > 0 || this.state.suggestSinger.length > 0) && this.state.query.length > 0 ) {
            if (this.state.suggestVideo.length > 0) {
                suggestVideo = this.state.suggestVideo.map((item: CategoryListItemInterface, key) => {
                    if (key < 5) {
                        if ( (item.name.length + item.artist.length) < 35 ) {
                            return (
                                <Link className="dropdown-item" to={'/play/' + item.id + '-' + item.slug} key={key}>
                                    {item.name} <span style={{color: "#a8acbb"}}>- {item.artist}</span>
                                </Link>
                            );
                        } else {
                            return (
                                <Link className="dropdown-item" to={'/play/' + item.id + '-' + item.slug} key={key}>
                                    <marquee>
                                        {item.name} <span style={{color: "#a8acbb"}}>- {item.artist}</span>
                                    </marquee>
                                </Link>
                            );
                        }
                    }
                });
            }
            if (this.state.suggestSinger.length > 0) {
                suggestSinger = this.state.suggestSinger.map((item: ArtistItemInterface, key) => {
                    if (key < 5) {
                        return (
                            <Link className="dropdown-item" to={'/ca-si/'+ item.id + '-' + item.slug} key={key}>{item.name}</Link>
                        );
                    }
                });
            }

            suggestSearch = (
                <div className="dropdown-menu search-suggest" style={{width: this.state.width}}>
                    <h6 className="dropdown-header">Bài hát</h6>
                    {suggestVideo}
                    <h6 className="dropdown-header">Ca sĩ</h6>
                    {suggestSinger}
                </div>
            );
        }

        return (
            <div className="row header">
                <form className="col-12" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="menu-left">
                        <img className="menu-img" src={ require("../asset/images/icon-menu.png") }/>
                    </div>
                    {logoData}
                    <div className="search-right">
                        <div className="input-group search-input">
                            <input type="text" name="search" className="form-control search-form" autoComplete="off" placeholder="Tìm kiếm"
                                   onChange={this.handleSearch.bind(this)}
                                />
                            <div className="input-group-append" onClick={this.handleSubmit.bind(this)}>
                                <span className="input-group-text" id="basic-addon1">
                                    <img className="search-img" src={ require("../asset/images/icon-search.png") }/>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
                <img className="menu-divide" src={ require("../asset/images/divide-menu.png") }/>

                {/*Gợi ý*/}
                {suggestSearch}
            </div>
        );
    }

}

export default Header

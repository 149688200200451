import * as React from "react"
import {HomeVideoItemInterface} from "../reducer/type/home";
import { Link } from 'react-router-dom'

interface PropsInterface {
    video: object[]
}

class HomeContent extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    render() {

        let homeContent = this.props.video.map((item: HomeVideoItemInterface, key: number) => {
           if (key > 9) return '';

           let cssItem = '';
           if (key % 2 == 0) {
               cssItem = 'pr-2';
           } else {
               cssItem = 'pl-2';
           }

           let homeItem = (
               <div className={"col-6 " + cssItem} key={key}>
                   <Link to={'/play/'+ item.id + '-' + item.slug}  className="content-item">
                       <div className="position-relative">
                           <img src={ item.thumbnail_url }/>
                           <div className="content-item-time-right">
                               <div className="content-item-time">
                                   { item.time }
                               </div>
                           </div>
                       </div>
                       <div className="content-item-head">
                           { item.name }
                       </div>
                       <div className="content-item-sub">
                           { item.artist }
                       </div>
                   </Link>
               </div>
           );

           return homeItem
        });

        return homeContent;
    }

}

export default HomeContent

import * as React from "react"
import { Link } from 'react-router-dom'

interface PropsInterface {
    text: string,
    button?: string
}

class ModalAlert extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    handleLogin() {
        window.location = '/dang-nhap' as any;
    }

    handlePackage() {
        window.location = '/goi-cuoc' as any;
    }

    render() {
        let buttonShow;
        if(typeof this.props.button == 'undefined') {
            buttonShow = (
                <div className="row">
                    <div className="col-6 pr-2">
                        <div onClick={this.handleLogin.bind(this)}
                             className="btn btn-secondary modal-alert-btn modal-alert-login">Đăng nhập
                        </div>
                    </div>
                    <div className="col-6 pl-2">
                        <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng
                        </button>
                    </div>
                </div>
            )
        } else if (this.props.button == 'package') {
            buttonShow = (
                <div className="row">
                    <div className="col-6 pr-2">
                        <div onClick={this.handlePackage.bind(this)}
                             className="btn btn-secondary modal-alert-btn modal-alert-login">Xem gói cước
                        </div>
                    </div>
                    <div className="col-6 pl-2">
                        <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng
                        </button>
                    </div>
                </div>
            )
        } else if (this.props.button == 'sms') {
            let checkMobile;
            checkMobile = (
                <div className="col-6 pr-2">
                    <a href="sms:9355&body=DK BL1" className="btn btn-secondary modal-alert-btn modal-alert-login">Soạn tin</a>
                </div>
            )

            buttonShow = (
                <div className="row">
                    {checkMobile}
                    <div className="col-6 pl-2">
                        <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng</button>
                    </div>
                </div>
            );
        } else if (this.props.button == "exit") {
            buttonShow = (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng</button>
                </div>
            </div>)
        } else {
            buttonShow = (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Đóng</button>
                </div>
            </div>)
        }

        return (
            <div className="modal" role="dialog" id="modal-alert">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center modal-alert-header">
                            { this.props.button == "exit" ? "Đã xóa" : "Chú ý" }
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <p className="modal-alert-text">{this.props.text}</p>
                                </div>
                            </div>
                            {buttonShow}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ModalAlert
import * as React from "react"

interface PropsInterface {
    time: number,
    handleClick: any
}

class ModalTime extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    render() {
        return (
            <div className="modal" role="dialog" id="modal-time">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center modal-alert-header">
                            Chú ý
                        </div>
                        <div className="modal-body">
                            <p className="modal-alert-text">
                                Hệ thống ghi nhận bạn đã xem đến
                                phút { Math.floor(this.props.time / 60) } giây { (this.props.time & 60) } bạn
                                có muốn xem tiếp.
                            </p>
                            <div className="row">
                                <div className='col-6'>
                                    <button type="button" className="btn btn-secondary modal-alert-btn" data-dismiss="modal">Không</button>
                                </div>
                                <div className='col-6'>
                                    <button type="button" className="btn btn-secondary modal-alert-btn" onClick={this.props.handleClick}>Xem tiếp</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ModalTime
import * as React from "react"
import { CategoryListItemInterface } from '../reducer/type/category'
import { Link } from 'react-router-dom'

interface PropsInterface {
    video: object[]
}

class CategoryList extends React.Component <PropsInterface> {

    constructor(props: PropsInterface) {
        super(props)
    }

    render() {
        let categoryList = this.props.video.map((item: CategoryListItemInterface, key: number) => {

            let cssItem = '';
            if (key % 2 == 0) {
                cssItem = 'pr-2 pl-0';
            } else {
                cssItem = 'pr-0 pl-2';
            }

            return (
                <div className={"col-6 ellipsis " + cssItem} key={key}>
                    <Link to={'/play/'+ item.id + '-' + item.slug} className="content-item">
                        <div className="position-relative">
                            <img className="bxh-item-img" src={ item.thumbnail_url }/>
                            <div className="content-item-time-right">
                                <div className="content-item-time">
                                    { item.time }
                                </div>
                            </div>
                        </div>
                        <div className="content-item-head">
                            { item.name }
                        </div>
                        <div className="content-item-sub">
                            { item.artist }
                        </div>
                    </Link>
                </div>
            )
        })

        return categoryList;
    }

}

export default CategoryList
